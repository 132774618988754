<template>
    <v-text-field hide-details="auto"
                  outlined
                  :label="$t(label)"
                  :inputName="inputName"
                  :id="inputName"
                  :value="inputValue"
                  :disabled="disabled ? true : false"
                  :type="type"
                  :prefix="prefix"
                  :suffix="suffix"
                  :readonly="readonly"
                  :rules="rules"
                  @focus="onInputFocus"
                  @keydown="keydown">
    </v-text-field>
</template>

<script>
import store from '../store/store';

export default {
    name: "Textinput",
    props: {
        inputName: {
            type: String,
            required: true,
        },
        label: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        onlyNumbers: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
        },
        prefix: {
            type: String,
        },
        suffix: {
            type: String,
        },
        rules: {
            type: Array,
        },
    },
    data (){
        return{
        }
    },
    methods: {
        /**
         * When Textinput is focused, send event to parent view
         */
        onInputFocus(input) {
            this.$emit("onInputFocus", input, this.onlyNumbers);
        },
        keydown(e){
            if(!e.key){
                 setTimeout(function(){
                     if (e.target.value){
                         let storeInput = {
                             name: e.target.id,
                             value: e.target.value,
                         }
                         store.commit('textinput', storeInput);
                     }
                 }, 100);
            } else {
                 e.preventDefault();
                 if (e.key.length === 1){
                     let storeInput = {
                         name: this.inputName,
                         value: e.key,
                     }
                     store.commit('textinputKeyboard', storeInput)
                 } else if (e.key === 'Backspace'){
                     store.commit('textDeleteKeyboard', this.inputName);
                 } else if (e.key === 'Enter'){
                     this.$root.$emit('hideKeyboard');
                 }

            }
        }
    },
    computed: {
        /**
         * link input value to correct parameter in store
         * @returns {*}
         */
        inputValue() {
            let pathParts = this.inputName.split('-');
            if (pathParts.length == 2){
                return this.$store.state[pathParts[0]][pathParts[1]];
            } else if (pathParts.length == 3){
                return this.$store.state[pathParts[0]][pathParts[1]][pathParts[2]];
            } else if (pathParts.length == 1){
                return this.$store.state[pathParts[0]];
            } else {
                return '';
            }
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>