<template>
    <div class="SwitchOnOff toggle-button" :class="[name, {'disabled': disabled}]">
        <div class="toggle">
            <p class="toggle-label toggle-label-on">ON</p>
            <label class="checkbox">
                <input type="checkbox" @click="switchClicked()">
                <div class="lever"></div>
            </label>
            <p class="toggle-label toggle-label-off" >OFF</p>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: "SwitchOnOff",
    props: {
        name: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        ...mapMutations([
            'changeSwitch',
        ]),
        /**
         * When Button is clicked, event is sent to parent view
         */
        switchClicked(){
            if (!this.disabled){
                this.changeSwitch(this.name);
                this.$emit('switchClicked');
            } else {
                console.log('switch disabled')
            }
        },
    },
}
</script>

<style scoped lang="scss">

.toggle-button{
    position: relative;
    width: 92px;
    height: 52px;

    .toggle{
        display: flex;
        justify-content: center;
        align-items: center;

        .toggle-label{
            position: absolute;
            top: 16px;
            z-index: 5;
            color: white;
            font-size: 0.85rem;
            font-weight: 500;
            pointer-events: none;

            &.toggle-label-on{
                opacity: 0;
                left: 13px;
            }

            &.toggle-label-off{
                right: 9px;
                opacity: 1;
            }
        }

        .checkbox{
            display: block;
            cursor: pointer;
            position: relative;
            height: 52px;
            width: 100%;
            background-color: $disiDarkblueLight;

            border-radius: 26px;

            input {
                display: none;
            }

            .lever {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 42px;
                height: 42px;
                background-color: white;
                border-radius: 50%;
                transition: all 0.2s linear;
                margin-left: 0;
            }
        }
    }
    &.active{
        .toggle{
            .toggle-label{
                &.toggle-label-on{
                    opacity: 1;
                }
                &.toggle-label-off{
                    opacity: 0;
                }
            }

        }
        .checkbox{
            background-color: $disiDarkblue;
            .lever{
                margin-left: 40px;
            }
        }
    }
}

//disabled
.toggle-button.disabled{
    pointer-events: none;

    &.toggle-button{
        .checkbox{
            background-color: $disiLightIrisblue!important;
        }

        &.active{
            .checkbox{
               // background-color: lighten($disiIrisblue, 30)!important;
              background-color: $disiLightIrisblue !important;
            }
        }
    }
}

</style>