<template>
    <div class="MethodsEdit full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('methods.methodName') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="editMethod-methodName" @onInputFocus="onInputFocus"
                                        :readonly="(!editable && this.editMethod.id != null) || !checkUserRights('methodsEdit')"
                                        :class="{'noAction': !editable || !checkUserRights('methodsEdit')}"
                                        :rules="[v => !!v || $t('validation.required'), rules.maxLengthName]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('methods.testingTime') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-dialog ref="testingTimeDialog" v-model="testingTimeModal"
                                      :return-value.sync="editMethod.testingTime" persistent width="350px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editMethod.testingTime"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details="auto"
                                        outlined
                                        :disabled="!editable"
                                        class="is-modal"
                                        :class="{'noAction': !checkUserRights('methodsEdit')}"
                                        :rules="[rules.testingTime]"
                                        id="testingTimeModal"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="editMethod.testingTime"
                                    scrollable
                                    header-color="disiDarkblue"
                                    width="350"
                                    format="24hr"
                                    use-seconds
                                    id="testingTimeModal-timepicker"
                                >
                                    <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2"
                                           @click="testingTimeModal = false;">{{ $t('footerButton.cancel') }}
                                    </v-btn>
                                    <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2"
                                           @click="$refs.testingTimeDialog.save(editMethod.testingTime)">
                                        {{ $t('footerButton.save') }}
                                    </v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editMethod-maxTestingTimeStatus"
                                           :disabled="!editable || !checkUserRights('methodsEdit')"
                                           :class="{'active': maxTestingTimeStatus}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-4">
                            <p>{{ $t('methods.maxTestingTime') }}</p>
                        </v-col>
                        <v-col class="col-6">
                            <v-dialog ref="maxTestingTimeDialog" v-model="maxTestingTimeModal"
                                      :return-value.sync="editMethod.maxTestingTime" persistent width="350px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editMethod.maxTestingTime"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details="auto"
                                        outlined
                                        :disabled="!editable || !maxTestingTimeStatus"
                                        class="is-modal"
                                        :class="{'noAction': !checkUserRights('methodsEdit')}"
                                        :rules="[rules.maxTestingTime]"
                                        id="maxTestingTimeModal"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="editMethod.maxTestingTime"
                                    scrollable
                                    header-color="disiDarkblue"
                                    width="350"
                                    format="24hr"
                                    use-seconds
                                    id="maxTestingTimeModal-timepicker"
                                >
                                    <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2"
                                           @click="maxTestingTimeModal = false;">{{ $t('footerButton.cancel') }}
                                    </v-btn>
                                    <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2"
                                           @click="$refs.maxTestingTimeDialog.save(editMethod.maxTestingTime)">
                                        {{ $t('footerButton.save') }}
                                    </v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('methods.selectMedia') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-select :items="activeMedia" item-text="name" item-value="id" v-model="editMethod.media"
                                      class="editMethod-media-dropdown" outlined hide-details="auto" attach
                                      :readonly="!editable || !checkUserRights('methodsEdit')"
                                      :class="{'noAction': !checkUserRights('methodsEdit')}"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('methods.selectBasket') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-select :items="selectBasket" item-text="name" item-value="value"
                                      v-model="editMethod.basket" class="editMethod-basket-dropdown" outlined
                                      hide-details="auto" attach
                                      :readonly="!editable || !checkUserRights('methodsEdit')"
                                      :class="{'noAction': !checkUserRights('methodsEdit')}"
                                      :rules="[v => !!v || $t('validation.required')]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editMethod-combinationTest"
                                           :disabled="!editable || editMethod.basket != 3 || !checkUserRights('methodsEdit')"
                                           :class="{'active': combinationTestStatus}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-10" :class="{'disabled': editMethod.basket != 3}">
                            <p>{{ $t('methods.combinationTest') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('methods.thresholdHeight') }}</p>
                        </v-col>
                        <v-col class="col-4">
                            <text-input inputName="editMethod-threshold" @onInputFocus="onInputFocus" suffix="mm"
                                        onlyNumbers :readonly="!editable || !checkUserRights('methodsEdit')"
                                        :class="{'noAction': !checkUserRights('methodsEdit')}"
                                        :rules="[rules.threshold, rules.maxDecimalPlaces1]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editMethod-temperatureStatus"
                                           :disabled="!editable || !checkUserRights('methodsEdit')"
                                           :class="{'active': temperatureStatus}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-4">
                            <p>{{ $t('methods.temperatureLimits') }}</p>
                        </v-col>
                        <v-col class="col-1 text-right" :class="{'disabled': !temperatureStatus}">
                            <p>{{ $t('general.from') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <text-input inputName="editMethod-temperatureMin" @onInputFocus="onInputFocus"
                                        :disabled="!temperatureStatus" suffix="°C" onlyNumbers
                                        :readonly="!editable || !checkUserRights('methodsEdit')"
                                        :class="{'noAction': !checkUserRights('methodsEdit')}"
                                        :rules="[rules.temperatureMin, rules.maxDecimalPlaces1]"/>
                        </v-col>
                        <v-col class="col-1 text-center" :class="{'disabled': !temperatureStatus}">
                            <p>{{ $t('general.to') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <text-input inputName="editMethod-temperatureMax" @onInputFocus="onInputFocus"
                                        :disabled="!temperatureStatus" suffix="°C" onlyNumbers
                                        :readonly="!editable || !checkUserRights('methodsEdit')"
                                        :class="{'noAction': !checkUserRights('methodsEdit')}"
                                        :rules="[rules.temperatureMax, rules.maxDecimalPlaces1]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editMethod-pretestStatus"
                                           :disabled="!editable || !checkUserRights('methodsEdit')"
                                           :class="{'active': pretestStatus}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-2">
                            <p>{{ $t('methods.pretest') }}</p>
                        </v-col>
                        <v-col class="col-2 text-right" :class="{'disabled': !pretestStatus}">
                            <p>{{ $t('methods.selectMedia') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <v-select :items="medialist" item-text="name" item-value="id"
                                      v-model="editMethod.pretestMedia" class="editMethod-pretestMedia-dropdown"
                                      outlined hide-details="auto" attach :disabled="!pretestStatus"
                                      :readonly="!editable || !checkUserRights('methodsEdit')"
                                      :class="{'noAction': !checkUserRights('methodsEdit')}"/>
                        </v-col>
                        <v-col class="col-2 text-right" :class="{'disabled': !pretestStatus}">
                            <p>{{ $t('methods.testingTime') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <v-dialog ref="pretestTestingTimeDialog" v-model="pretestTestingTimeModal"
                                      :return-value.sync="editMethod.pretestTestingTime" persistent width="350px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editMethod.pretestTestingTime"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details="auto"
                                        outlined
                                        :disabled="!editable || !pretestStatus"
                                        class="is-modal"
                                        :class="{'noAction': !checkUserRights('methodsEdit')}"
                                        :rules="[rules.pretestTestingTime]"
                                        id="pretestTestingTimeModal"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-model="editMethod.pretestTestingTime"
                                    scrollable
                                    header-color="disiDarkblue"
                                    width="350"
                                    format="24hr"
                                    use-seconds
                                    id="pretestTestingTimeModal-timepicker"
                                >
                                    <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2"
                                           @click="pretestTestingTimeModal = false;">{{ $t('footerButton.cancel') }}
                                    </v-btn>
                                    <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2"
                                           @click="$refs.pretestTestingTimeDialog.save(editMethod.pretestTestingTime)">
                                        {{ $t('footerButton.save') }}
                                    </v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editMethod-assessmentStatus"
                                           :disabled="!editable || !checkUserRights('methodsEdit')"
                                           :class="{'active': assessmentStatus}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-2">
                            <p>{{ $t('methods.assessment') }}</p>
                        </v-col>
                        <v-col class="col-3 text-right" :class="{'disabled': !assessmentStatus}">
                            <p>{{ $t('methods.minDisintegrated') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <text-input inputName="editMethod-assessmentMinDisintegrated" @onInputFocus="onInputFocus"
                                        :disabled="!assessmentStatus" onlyNumbers
                                        :readonly="!editable || !checkUserRights('methodsEdit')"
                                        :class="{'noAction': !checkUserRights('methodsEdit')}"
                                        :rules="[rules.assessment]"/>
                        </v-col>
                        <v-col class="col-1 text-center" :class="{'disabled': !assessmentStatus}">
                            <p>{{ $t('general.of') }}</p>
                        </v-col>
                        <v-col class="col-2">
                            <text-input inputName="editMethod-assessmentMaxDisintegrated" @onInputFocus="onInputFocus"
                                        :disabled="!assessmentStatus" onlyNumbers
                                        :readonly="!editable || !checkUserRights('methodsEdit')"
                                        :class="{'noAction': !checkUserRights('methodsEdit')}"
                                        :rules="[rules.assessmentMax]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editMethod-fastDisintegration"
                                           :disabled="!editable || withoutDisc || manual || !checkUserRights('methodsEdit')"
                                           :class="{'active': fastDisintegration}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-6" :class="{'disabled': withoutDisc || manual}">
                            <p>{{ $t('methods.fastDisintegration') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editMethod-withoutDisc"
                                           :disabled="!editable || !checkUserRights('methodsEdit')"
                                           :class="{'active': withoutDisc}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-6">
                            <p>{{ $t('methods.withoutDisc') }}</p>
                        </v-col>
                    </v-row>

<!--                    <v-row class="d-flex align-center">-->
<!--                        <v-col class="col-2">-->
<!--                            <switch-on-off name="editMethod-manual"-->
<!--                                           :disabled="!editable || !checkUserRights('methodsEdit')"-->
<!--                                           :class="{'active': manual}" class="mr-4"/>-->
<!--                        </v-col>-->
<!--                        <v-col class="col-6">-->
<!--                            <p>{{ $t('methods.manual') }}</p>-->
<!--                        </v-col>-->
<!--                    </v-row>-->

                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="editMethod-status" :disabled="!checkUserRights('methodsEdit')"
                                           :class="{'active': status}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-6">
                            <p>{{ $t('general.status') }}</p>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress"
                           :class="{'disabled': printDisabled || !checkUserRights('methodsPrint')}"
                           @footerButtonClick="print"></footer-button>
            <footer-button buttonFunction="delete"
                           :class="{'disabled': !editable || editMethod.id == null || !checkUserRights('methodsDelete')}"
                           @footerButtonClick="deleteMethod"></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToMethods"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress"
                           :class="{'disabled': saveDisabled || !checkUserRights('methodsEdit')}"
                           @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import FooterButton from '@/components/FooterButton.vue'
import {mapGetters, mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'MethodsEdit',
    components: {
        TextInput,
        SwitchOnOff,
        FooterButton,
    },
    props: {},
    data() {
        return {
            selectBasket: [
                {
                    'name': `3 ${this.$t('general.tubes')}`,
                    'value': 3
                }, {
                    'name': `6 ${this.$t('general.tubes')}`,
                    'value': 6
                }
            ],
            testingTimeModal: false,
            maxTestingTimeModal: false,
            pretestTestingTimeModal: false,
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            showPrintProgress: false,
            showPrintSuccess: false,
            originalMethod: {},
            rules: {
                numeric: v => {
                    if (v) {
                        let numeric = /^[0-9]+$/;
                        return numeric.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
                maxLengthName: v => {
                    if (v) {
                        return v.length < 24 || `${this.$t('validation.chooseShorterName')}`;
                    } else return true;
                },
                testingTime: v => {
                    if (v && v != '00:00:00') {
                        return !!v;
                    } else {
                        return `${this.$t('validation.required')}`;
                    }
                },
                maxTestingTime: v => {
                    if (this.maxTestingTimeStatus) {
                        if (v && v != '00:00:00') {
                            let maxTestingTimeTimestamp = this.timestampFormat(v);
                            let testingTimeTimestamp = this.timestampFormat(this.editMethod.testingTime);
                            if (maxTestingTimeTimestamp > testingTimeTimestamp) {
                                return true;
                            } else {
                                return `${this.$t('validation.maxTestingTimeLonger')}`;
                            }
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
                threshold: v => {
                    if (v) {
                        return (parseFloat(v) >= 0.0 && parseFloat(v) <= 2.0) || `${this.$t('validation.minimum')}: 0.0mm, ${this.$t('validation.maximum')}: 2.0mm`;
                    } else return true;
                },
                temperatureMin: v => {
                    if (this.editMethod.temperatureStatus) {
                        if (v) {
                            return (parseFloat(v) >= 0.0 && parseFloat(v) <= 40.0) || `${this.$t('validation.minimum')}: 0.0°C, ${this.$t('validation.maximum')}: 40°C`;
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
                temperatureMax: v => {
                    if (this.editMethod.temperatureStatus) {
                        if (v) {
                            if (this.editMethod.temperatureMin) {
                                return (parseFloat(v) >= parseFloat(this.editMethod.temperatureMin) && parseFloat(v) <= 40.0) || `${this.$t('validation.minimum')}: ${this.editMethod.temperatureMin}°C, ${this.$t('validation.maximum')}: 40°C`;
                            } else {
                                return (parseFloat(v) >= 20 && parseFloat(v) <= 40.0) || `${this.$t('validation.minimum')}: 20°C, ${this.$t('validation.maximum')}: 40°C`;

                            }
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
                pretestTestingTime: v => {
                    if (this.pretestStatus) {
                        if (v && v != '00:00:00') {
                            return !!v;
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
                assessment: v => {
                    if (this.assessmentStatus) {
                        let numeric = /^[0-9]+$/;
                        if (numeric.test(v) && parseFloat(v) >= 1 && parseFloat(v) <= 6) {
                            return !!v;
                        } else {
                            return `${this.$t('validation.minimum')}: 1, ${this.$t('validation.maximum')}: 6`;
                        }
                    } else return true;
                },
                assessmentMax: v => {
                    if (this.assessmentStatus) {
                        let numeric = /^[0-9]+$/;
                        let minimum = parseInt(this.editMethod.assessmentMinDisintegrated) + 1;
                        if (minimum > 6) {
                            minimum = 6;
                        }
                        if (minimum) {
                            if (numeric.test(v) && parseFloat(v) >= minimum && parseFloat(v) <= 6) {
                                return !!v;
                            } else {
                                return `${this.$t('validation.minimum')}: ${minimum}, ${this.$t('validation.maximum')}: 6`;
                            }
                        } else {
                            if (numeric.test(v) && parseFloat(v) >= 1 && parseFloat(v) <= 6) {
                                return !!v;
                            } else {
                                return `${this.$t('validation.minimum')}: 1, ${this.$t('validation.maximum')}: 6`;
                            }
                        }

                    } else return true;
                },
                maxDecimalPlaces1: v => {
                    if (v) {
                        let test = v.toString().split('.');
                        if (test[1]) {
                            if (test[1].length > 1) {
                                return `${this.$t('validation.chooseShorterDecimalPlace')}`;
                            }
                        }
                        return true;
                    } else return true;
                }
            }
        }
    },
    computed: {
        ...mapState([
            'editMethod',
            'medialist'
        ]),
        ...mapGetters({
            activeMedia: 'activeMedia',
        }),
        maxTestingTimeStatus() {
            return this.$store.state.editMethod.maxTestingTimeStatus;
        },
        combinationTestStatus() {
            return this.$store.state.editMethod.combinationTest;
        },
        temperatureStatus() {
            return this.$store.state.editMethod.temperatureStatus;
        },
        pretestStatus() {
            return this.$store.state.editMethod.pretestStatus;
        },
        assessmentStatus() {
            return this.$store.state.editMethod.assessmentStatus;
        },
        fastDisintegration() {
            return this.$store.state.editMethod.fastDisintegration;
        },
        withoutDisc() {
            return this.$store.state.editMethod.withoutDisc;
        },
        manual() {
            return this.$store.state.editMethod.manual;
        },
        status() {
            return this.$store.state.editMethod.status;
        },
        editable() {
            return this.editMethod.editable;
        },
        printDisabled() {
            if (this.editMethod.id != null) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        goToMethods() {
            this.$router.push('methods');
        },
        print() {
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/methods/print', {'id': parseInt(this.editMethod.id)}])
                .then(response => {
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(() => {
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                });
        },
        deleteMethod() {
            this.$store.dispatch('postAxiosNoSetter', ['/disi/methods/delete', {'id': parseInt(this.editMethod.id)}])
                .then(response => {
                    if (response.status === 200) {
                        this.goToMethods();
                    }
                })
        },
        save() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.showSaveProgress = true;
                if (this.editMethod.id != null) {
                    let Method = {}
                    //optional
                    if (this.editMethod.methodName != this.originalMethod.methodName && this.editMethod.methodName != "") {
                        Method.methodName = this.editMethod.methodName;
                    }
                    if (this.editMethod.testingTime != this.originalMethod.testingTime && this.editMethod.testingTime != "") {
                        Method.testingTime = this.timestampFormat(this.editMethod.testingTime);
                    }
                    if (this.editMethod.maxTestingTimeStatus != this.originalMethod.maxTestingTimeStatus) {
                        Method.maxTestingTimeStatus = this.editMethod.maxTestingTimeStatus;
                    }
                    if (this.editMethod.maxTestingTime != this.originalMethod.maxTestingTime && this.editMethod.maxTestingTime != "" && this.editMethod.maxTestingTimeStatus === true) {
                        Method.maxTestingTime = this.timestampFormat(this.editMethod.maxTestingTime);
                    }
                    if (this.editMethod.media != this.originalMethod.media) {
                        Method.media = parseInt(this.editMethod.media);
                    }
                    if (this.editMethod.basket != this.originalMethod.basket) {
                        Method.basket = parseInt(this.editMethod.basket);
                    }
                    if (this.editMethod.combinationTest != this.originalMethod.combinationTest) {
                        Method.combinationTest = this.editMethod.combinationTest;
                    }
                    if (this.editMethod.threshold != this.originalMethod.threshold && this.editMethod.threshold != "") {
                        Method.threshold = parseFloat(this.editMethod.threshold);
                    }
                    if (this.editMethod.temperatureStatus != this.originalMethod.temperatureStatus) {
                        Method.temperatureStatus = this.editMethod.temperatureStatus;
                    }
                    if (this.editMethod.temperatureStatus === true) {
                        if (this.editMethod.temperatureMin != this.originalMethod.temperatureMin && this.editMethod.temperatureMin != "") {
                            Method.temperatureMin = parseInt(this.editMethod.temperatureMin);
                        }
                        if (this.editMethod.temperatureMax != this.originalMethod.temperatureMax && this.editMethod.temperatureMax != "") {
                            Method.temperatureMax = parseInt(this.editMethod.temperatureMax);
                        }
                    }
                    if (this.editMethod.pretestStatus != this.originalMethod.pretestStatus) {
                        Method.pretestStatus = this.editMethod.pretestStatus;
                    }
                    if (this.editMethod.pretestStatus === true) {
                        if (this.editMethod.pretestMedia != this.originalMethod.pretestMedia) {
                            Method.pretestMedia = parseInt(this.editMethod.pretestMedia);
                        }
                        if (this.editMethod.pretestTestingTime != this.originalMethod.pretestTestingTime && this.editMethod.pretestTestingTime != "") {
                            Method.pretestTestingTime = this.timestampFormat(this.editMethod.pretestTestingTime);
                        }
                    }
                    if (this.editMethod.assessmentStatus != this.originalMethod.assessmentStatus) {
                        Method.assessmentStatus = this.editMethod.assessmentStatus;
                    }
                    if (this.editMethod.assessmentStatus === true) {
                        if (this.editMethod.assessmentMinDisintegrated != this.originalMethod.assessmentMinDisintegrated && this.editMethod.assessmentMinDisintegrated != "") {
                            Method.assessmentMinDisintegrated = parseInt(this.editMethod.assessmentMinDisintegrated);
                        }
                        if (this.editMethod.assessmentMaxDisintegrated != this.originalMethod.assessmentMaxDisintegrated && this.editMethod.assessmentMaxDisintegrated != "") {
                            Method.assessmentMaxDisintegrated = parseInt(this.editMethod.assessmentMaxDisintegrated);
                        }
                    }
                    if (this.editMethod.fastDisintegration != this.originalMethod.fastDisintegration) {
                        Method.fastDisintegration = this.editMethod.fastDisintegration;
                    }
                    if (this.editMethod.withoutDisc != this.originalMethod.withoutDisc) {
                        Method.withoutDisc = this.editMethod.withoutDisc;
                    }
                    if (this.editMethod.manual != this.originalMethod.manual) {
                        Method.manual = this.editMethod.manual;
                    }
                    if (this.editMethod.status != this.originalMethod.status) {
                        Method.status = this.editMethod.status;
                    }
                    if (Object.keys(Method).length === 0 && Method.constructor === Object) {
                        this.showSaveProgress = false;
                        this.showSaveSuccess = true;
                        this.watcher();
                        setTimeout(() => {
                            this.saveDisabled = true;
                            this.showSaveSuccess = false;
                        }, 800)
                    } else {
                        //must send
                        Method.id = parseInt(this.editMethod.id);
                        this.$store.dispatch('postAxiosNoSetter', ['disi/methods/edit', Method])
                            .then(response => {
                                this.showSaveProgress = false;
                                if (response.status === 200) {
                                    this.showSaveSuccess = true;
                                    this.watcher();
                                    setTimeout(() => {
                                        this.saveDisabled = true;
                                        this.showSaveSuccess = false;
                                    }, 800)
                                }
                            })
                    }
                } else {
                    let Method = {
                        //must send
                        "methodName": this.editMethod.methodName,
                        "testingTime": this.timestampFormat(this.editMethod.testingTime),
                        "maxTestingTimeStatus": this.editMethod.maxTestingTimeStatus,
                        "maxTestingTime": this.timestampFormat(this.editMethod.maxTestingTime),
                        "media": parseInt(this.editMethod.media),
                        "basket": parseInt(this.editMethod.basket),
                        "combinationTest": this.editMethod.combinationTest,
                        "threshold": parseFloat(this.editMethod.threshold),
                        "temperatureStatus": this.editMethod.temperatureStatus,
                        "temperatureMin": parseInt(this.editMethod.temperatureMin),
                        "temperatureMax": parseInt(this.editMethod.temperatureMax),
                        "pretestStatus": this.editMethod.pretestStatus,
                        "pretestTestingTime": this.timestampFormat(this.editMethod.pretestTestingTime),
                        "assessmentStatus": this.editMethod.assessmentStatus,
                        "assessmentMinDisintegrated": parseInt(this.editMethod.assessmentMinDisintegrated),
                        "assessmentMaxDisintegrated": parseInt(this.editMethod.assessmentMaxDisintegrated),
                        "fastDisintegration": this.editMethod.fastDisintegration,
                        "withoutDisc": this.editMethod.withoutDisc,
                        "manual": this.editMethod.manual,
                        "status": this.editMethod.status
                    }
                    if (parseInt(this.editMethod.pretestMedia) > 0) {
                        Method.pretestMedia = parseInt(this.editMethod.pretestMedia)
                    }
                    this.$store.dispatch('postAxiosNoSetter', ['disi/methods/new', Method])
                        .then(response => {
                            this.showSaveProgress = false;
                            if (response.status === 200) {
                                this.editMethod.id = response.data.id;
                                this.showSaveSuccess = true;
                                this.watcher();
                                setTimeout(() => {
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            }
                        })
                }
            } else {
                //scroll to error
                setTimeout(() => {
                    let message = document.querySelector('.v-input.error--text');
                    if (message) {
                        let messagePosition = message.getBoundingClientRect();
                        document.querySelector('.content-area').scrollTo({
                            top: messagePosition.top,
                            behavior: 'smooth'
                        });
                    } else {
                        document.querySelector('.content-area').scrollTo({top: 0, behavior: 'smooth'});
                    }
                }, 100)
            }

        },
        watcher() {
            //copy editMethod to originalMethod for comparison
            this.originalMethod = JSON.parse(JSON.stringify(this.editMethod));

            let methodName = this.$watch('$store.state.editMethod.methodName', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let testingTime = this.$watch('$store.state.editMethod.testingTime', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let maxTestingTimeStatus = this.$watch('$store.state.editMethod.maxTestingTimeStatus', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let maxTestingTime = this.$watch('$store.state.editMethod.maxTestingTime', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let media = this.$watch('$store.state.editMethod.media', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let basket = this.$watch('$store.state.editMethod.basket', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let combinationTest = this.$watch('$store.state.editMethod.combinationTest', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let threshold = this.$watch('$store.state.editMethod.threshold', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let temperatureStatus = this.$watch('$store.state.editMethod.temperatureStatus', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let temperatureMin = this.$watch('$store.state.editMethod.temperatureMin', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let temperatureMax = this.$watch('$store.state.editMethod.temperatureMax', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let pretestStatus = this.$watch('$store.state.editMethod.pretestStatus', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let pretestMedia = this.$watch('$store.state.editMethod.pretestMedia', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let pretestTestingTime = this.$watch('$store.state.editMethod.pretestTestingTime', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let assessmentStatus = this.$watch('$store.state.editMethod.assessmentStatus', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let assessmentMinDisintegrated = this.$watch('$store.state.editMethod.assessmentMinDisintegrated', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let assessmentMaxDisintegrated = this.$watch('$store.state.editMethod.assessmentMaxDisintegrated', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let fastDisintegration = this.$watch('$store.state.editMethod.fastDisintegration', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let withoutDisc = this.$watch('$store.state.editMethod.withoutDisc', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let manual = this.$watch('$store.state.editMethod.manual', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let status = this.$watch('$store.state.editMethod.status', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function () {
                methodName();
                testingTime();
                maxTestingTimeStatus();
                maxTestingTime();
                media();
                basket();
                combinationTest();
                threshold();
                temperatureStatus();
                temperatureMin();
                temperatureMax();
                pretestStatus();
                pretestMedia();
                pretestTestingTime();
                assessmentStatus();
                assessmentMinDisintegrated();
                assessmentMaxDisintegrated();
                fastDisintegration();
                withoutDisc();
                manual();
                status();
            };
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#' + input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$emit("resetInput");
        this.watcher();

        //set combi test off if 6 tubes is selected
        this.$watch('$store.state.editMethod.basket', function () {
            if (this.$store.state.editMethod.basket == '6') {
                this.$store.commit('setCombiTestOff', 'editMethod');
            }
        });

        //set fast disintigration off if manual is on
        this.$watch('$store.state.editMethod.manual', function () {
            if (this.$store.state.editMethod.manual) {
                this.$store.commit('setFastDisintigrationOff', 'editMethod');
            }
        });

        //set fast disintigration off if without disc is on
        this.$watch('$store.state.editMethod.withoutDisc', function () {
            if (this.$store.state.editMethod.withoutDisc) {
                this.$store.commit('setFastDisintigrationOff', 'editMethod');
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        if (this.saveDisabled) {
            next()
        } else {
            this.$confirm({
                message: this.$t('alerts.unsavedChanges'),
                show: true,
                cancelText: this.$t('general.cancel'),
                continueText: this.$t('general.continue')
            })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
</style>